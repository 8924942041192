<template>  
    <div>
        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
               <a :href="policies[policyType].url" :class="linkClass">
                <v-icon v-bind="attrs" :color="color" v-on="on" :size="iconSize">mdi-{{icon ? icon: policies[policyType].icon  }}</v-icon>
               </a>  
            </template>
            <span>{{ title ? title : policies[policyType].title }}</span>
        </v-tooltip> 
    </div>
</template>

<script>
    export default({
        name:'SalesPolicy',
        props:{
            color: { 
                default: "gray"
            },
            icon: { 
                default: ""
            },
            iconSize: { 
                default: 25
            },
            linkClass:{
                default:''
            },
            policyType:{
                default:1
            },
            title:{
                default:''
            }
        } ,
        data() {
            return {
            policies:{
               1:{ url:'/vinculos',title:'Política de entrega', icon:'truck-delivery-outline' }, //politica de entregas
               2:{ url:'/vinculos',title:'Política de crédito minoristas', icon:'file-certificate-outline'  }, // Política de credito minoristas
               3:{ url:'/vinculos',title:'Política de ventas', icon:''  }, //politica de ventas
               4:{ url:'',title:'Política de auto', icon:''  }, // Politica de auto
            },   
        }
    }
})
</script>