<template>
  <div class="bg-white">
    <v-container fluid style="padding-left:3%;padding-right: 3%; ">    
    <v-row > 
      <v-col arial- cols="12" sm="6" md="4" lg="3" class="justify-center">
        <CustomerCard :seller="seller" :cardSeller="cardSeller" :CancelToken="CancelToken"></CustomerCard>
      </v-col>
      <v-col cols="12" sm="6" md="8" lg="9">
        <AverageOrders :seller="seller"></AverageOrders>
      </v-col>
      </v-row>  
      <v-row>
      <v-col  cols="12" md="12">
        <v-card style="box-shadow: -1px 1px 7px 0px rgba(224,37,37,0.45);-webkit-box-shadow: -1px 1px 7px 0px rgba(224,37,37,0.45); -moz-box-shadow: -1px 1px 7px 0px rgba(224,37,37,0.45);">
          <v-card-text class="">
          <net-sales :seller="seller" class="w-100" v-on:showNetSales="showInfo" v-on:netSalesAmount="setNetSales"></net-sales>
            <hr />
            <Billing :seller="seller"  :cardSeller="cardSeller" :CancelToken="CancelToken" @desactiveLoader="finishBilling = true"></Billing>
          </v-card-text>
        </v-card> 
        <CustomerList :seller="seller" :user="user" @desactiveLoader="finishCustomerList = true"> </CustomerList> 
      </v-col>
    </v-row>   
    <v-dialog v-model="showModal" :persistent="true">
      <!-- 500 para table 1 |  350 a table 2 | 800 table 3 -->
      <v-card class="pa-0 pa-sm-2 pa-md-3">
         <ManagerPaymentHistory :seller="seller" :info="cardSeller" v-if="showManagerPaymentHistory" @closeModal="closeModal()"> </ManagerPaymentHistory>
        <div  class="text-right ma-5 pb-1" >
        <v-btn small outlined color="success" @click="closeModal()">
        Cerrar
      </v-btn>
      </div>
      </v-card>
    </v-dialog> 
    <LegacyFooter></LegacyFooter>
  </v-container> 
  </div> 
</template>
<script src="https://cdnjs.cloudflare.com/ajax/libs/html2pdf.js/0.8.1/html2pdf.bundle.min.js" integrity="sha512-vDKWohFHe2vkVWXHp3tKvIxxXg0pJxeid5eo+UjdjME3DBFBn2F8yWOE0XmiFcFbXxrEOR1JriWEno5Ckpn15A==" crossorigin="anonymous" referrerpolicy="no-referrer"></script>
<script>
 
import ManagerPaymentHistory from "./includes/modals/ManagerPaymentHistory.vue";
import Billing from "./includes/Billing.vue";
import CustomerCard from "./includes/CustomerCard.vue";
import { mapGetters, mapActions } from "vuex";
import axios from "@/plugins/axios";
import NetSales from "./components/NetSales.vue";
import AverageOrders from "./includes/AverageOrders.vue";
import moment from 'moment' 
import CustomerList from "./includes/CustomerList.vue"; 
import LegacyFooter from "./components/LegacyFooter.vue"
import html2pdf from "html2pdf.js"; 
import MixinService from '@/mixins/service';

export default {
  mixins:[MixinService], 
  name: "SellerResults",
  components: { 
    CustomerList, 
    LegacyFooter,
    AverageOrders,NetSales,CustomerCard,ManagerPaymentHistory,
    Billing,
  },
  beforeRouteEnter(to, from, next) {
      //se ejecuta el loader solo si venimos desde el componente SignIn
      next(vm => {
          if (from.name === 'SignIn') {
            console.log('se activo')
              vm.activeLoader();
          }
      });
  },
  created(){ 
    var seller =[ ];
    if (this.$hasAnyRole(['Seller'])) {
      seller.seller_id =  this.user.employee_number;
      }else{
      const currentSeller = JSON.parse(localStorage.getItem('currentSellerCode')) || [];
      if (currentSeller.length<1) {
        this.$router.push({name:'Home'})
      }   
      seller.seller_id =  currentSeller; 
    } 
    seller.channel = this.user.VTWEG    
    this.sellerInfo(seller);
  },  
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
      userChannel: "auth/channel"
    })
},
  data: () => ({
    finishBilling: false,
    finishCustomerList: false,
    CancelToken: '',
    cardSeller: {
      fact_men_anterior: 0,
      zona: '',
      detalle_zona: '',
      antiguedad_iusa: 0,
      antiguedad_zona: 0,
      loader: true,
      pAutos:0
    },   
    showModal: false, 
    showManagerPaymentHistory:false,    
    seller:[],   
  }),
  watch:{
    finishBilling(){
      this.validateLoader();
    },
    finishCustomerList(){
      this.validateLoader();
    }
  },
  methods: {   
    ...mapActions({
        sendToPDF: 'printer/sendToPDF',
        activeLoader: 'loader/activeLoader',
        desactiveLoader: 'loader/desactiveLoader'
    }),  
    validateLoader() {
      if(this.finishBilling && this.finishCustomerList){
          this.desactiveLoader();
      }
    },
     monthDiff(dateFrom, dateTo) { 
      return dateTo.getMonth() - dateFrom.getMonth() + (12 * (dateTo.getFullYear() - dateFrom.getFullYear())) 
    },
    sellerInfo(data){
      axios
          .post("/seller/home/general-info", {
            seller_id: data.seller_id, 
            channel:  data.channel
          }, {
            noCancelRequest:true,
            cachable:true,
            //cancelToken: this.CancelToken.token
          })
          .then((response) => {
            if (response.data?.success==false) {
                console.log( response.data)
            } else {
                this.seller = response.data[0];   
            }  
            var MonthsInIUSA = new Date( this.seller.DAT01);
            this.cardSeller.antiguedad_iusa = this.monthDiff(new Date(MonthsInIUSA ),new Date())
            const zone= this.seller.BZIRK.split("-")
            this.cardSeller.detalle_zona = zone[1];
            this.cardSeller.zona = zone[0];
            this.cardSeller.pAutos = this.seller.PAUTOS     
            this.sendToPDF({active: true}) 
          //  this.cardSeller.antiguedad_zona = this.seller?.DATZN;
          })
          .catch((e) => {
            console.log(e);
          }) ;
    }, 
    setNetSales(amount){
      this.cardSeller.fact_men_anterior = amount 
      this.cardSeller.loader = false
    },  
    showInfo( ) { 
      this.showModal = true;
      this.showManagerPaymentHistory=true; 
    }, 
    closeModal(){ 
      this.showModal = false;
      this.showManagerPaymentHistory=false; 
    },   
  }, 
  beforeRouteLeave(to, from, next) {
    // Cancela la solicitud al cambiar de ruta
    // this.CancelToken.cancel('Request canceled due to route change');
    next();
  },

};
</script>
<style scoped src="../../../public/css/seller.css"></style>

