import store from "@/store";
//import {hasAnyRole} from "./index"
import CreditIndex from "../views/credit/CreditIndex.vue";
import CreditHistory from "../views/credit/CreditHistory.vue";
import CreditDetail from "../views/credit/CreditDetail.vue";
import PromissoryIndex from "../views/credit/PromissoryIndex.vue";

export default [
  {
    path: "/credit/customers-request",
    name: "CreditIndex",
    component: CreditIndex,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({ name: "SignIn" });
      }
      next();
    },
  },
  {
    path: "/credit/history",
    name: "CreditHistory",
    component: CreditHistory,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({ name: "SignIn" });
      }
      next();
    },
  },
  {
    path: "/credit/customers-request/:customerId",
    name: "CreditDetail",
    component: CreditDetail,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({ name: "SignIn" });
      }
      next();
    },
  },
  {
    path: "/credit/promissory-notes",
    name: "PromissoryIndex",
    component: PromissoryIndex,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({ name: "SignIn" });
      }
      next();
    },
  },
];
