<template>
  <div>
    <v-container fluid class="white lighten-5 py-7" ref="printReport">
      <v-card class="pa-5 mb-0 ma-5 card-shadow" rounded="0" v-if="currentLevelLabel && currentLevelMatch(['family', 'material'])">
        <h2 class="text-h5 ma-0 text-uppercase font-weight-bold">{{ currentLevelLabel }}</h2>
      </v-card>

      <v-card class="pa-5 my-0 ma-5 card-shadow" rounded="0">
        <v-row no-gutters>
          <v-col cols="9" class="d-flex gap-2 align-center">
            <v-btn class="hide-on-print" icon color="black" @click="prevLevel()" :disabled="currentLevelIndex <= 0">
              <v-icon>mdi-arrow-u-left-top</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="3" class="d-flex justify-end hide-on-print">
            <!--<v-icon style="font-size: 30px">mdi-notebook</v-icon>-->
           <!-- <menu-print @generarDocumento="generatePdfFromView('printReport', 'cotización.pdf', 'hide-on-print')" :exist-excel="false" />-->
          </v-col>
        </v-row>

        <div class="row justify-center">
          <div class="box vinculo1">
            <div class="title" style="background-color: #D7263D;">{{ boxes[0].title }}</div>
            <div class="align-center" style="margin-top: 1.3cm;">
              <v-img width="45" src="img/logo_iusa.svg" class="mt-n10" style="display: inline-block; margin-right: 0.2cm;"></v-img>
              <a href="http://200.79.162.123/reportes/folios/folios.asp" target="_blank" style="text-decoration: underline; color:black ">{{ boxes[0].text }}</a>
            </div>
          </div>
          <div class="box vinculo2">
            <div class="title" style="background-color: #DE639A;">{{ boxes[1].title }}</div>
            <div class="align-center" style="margin-top: 1.3cm;">
              <v-img width="45" src="img/logo_iusa.svg" class="mt-n10" style="display: inline-block; margin-right: 0.2cm;"></v-img>
              <a href="http://172.16.171.10/pro/reportes/pedpend/default.asp" target="_blank" style="text-decoration: underline; color:black;">{{ boxes[1].text }}</a>
            </div>
          </div>
          <div class="box consulta3">
            <div class="title" style="background-color: #F9BE00;">{{ boxes[2].title }}</div>
            <div class="align-center" style="margin-top: 1.3cm;">
              <v-img width="45" src="img/logo_iusa.svg" class="mt-n10" style="display: inline-block; margin-right: 0.2cm;"></v-img>
              <a href="http://200.79.162.123/reportes/folios/cancelados.asp" target="_blank" style="text-decoration: underline; color:black; display: inline-block;">
                {{ boxes[2].text }}
              </a>
            </div>
          </div>
          <div class="box vinculo4">
            <div class="title" style="background-color: #1B998B;">{{ boxes[3].title }}</div>
            <div class="align-center" style="margin-top: 1.3cm;">
              <v-img width="45" src="img/logo_iusa.svg" class="mt-n10" style="display: inline-block; margin-right: 0.2cm;"></v-img>
              <a href="http://172.16.171.10/reportes/materiales/" target="_blank" style="text-decoration: underline; color:black ">{{ boxes[3].text }}</a>
            </div>
          </div>
          <div class="box consulta1">
            <div class="title" style="background-color: rgb(38,163,215);">{{ boxes[4].title }}</div>
            <div class="align-center" style="margin-top: 1.3cm;">
              <v-img width="45" src="img/logo_iusa.svg" class="mt-n10" style="display: inline-block; margin-right: 0.2cm;"></v-img>
              <a :href="`${$filesUrl}/documentation/POLITICA_ENTREGAS_A_CLIENTES.pdf`" download="POLITICA_ENTREGAS_A_CLIENTES.pdf" style="text-decoration: underline; color:black ">
                {{ boxes[4].text }}
              </a>
            </div>
          </div>
          <div class="box consulta2" v-if="isPr">
            <div class="title" style="background-color: rgb(155,72,206);">{{ boxes[5].title }}</div>
            <div class="align-center" style="margin-top: 1.3cm;">
              <v-img width="45" src="img/logo_iusa.svg" class="mt-n10" style="display: inline-block; margin-right: 0.2cm;"></v-img>
              <a :href="`${$filesUrl}/documentation/POLITICA_VENTAS_CRÉDITO_DISTRIBUCIÓN.pdf`" download="POLITICA_VENTAS_CRÉDITO_DISTRIBUCIÓN.pdf" style="text-decoration: underline; color:black ">
                {{ boxes[5].text }}
              </a>
            </div>
          </div>
          <div class="box consulta2" v-else>
            <div class="title" style="background-color: rgb(155,72,206);">{{ boxes[5].title }}</div>
            <div class="align-center" style="margin-top: 1.3cm;">
              <v-img width="45" src="img/logo_iusa.svg" class="mt-n10" style="display: inline-block; margin-right: 0.2cm;"></v-img>
              <a :href="`${$filesUrl}/documentation/POLITICA_VENTAS_CRÉDITO_MINORISTAS.pdf`" download="POLITICA_VENTAS_CRÉDITO_MINORISTAS.pdf" style="text-decoration: underline; color:black ">
                {{ boxes[5].text }}
              </a>
              <!--this.$baseUrl + "/public/documentation/POLITICA_ENTREGAS_A_CLIENTES.pdf",
                  this.$baseUrl + "/public/documentation/POLITICA_VENTAS_CRÉDITO_DISTRIBUCIÓN.pdf",
                  this.$baseUrl + "/public/documentation/POLITICA_VENTAS_CRÉDITO_MINORISTAS.pdf
                  -->
            </div>
          </div>
        </div>
        <div class="row justify-center">
          <div class="box vinculo3">
            <div class="title" style="background-color: rgb(82,155,39);">{{ boxes[6].title }}</div>
            <div class="align-center" style="margin-top: 1.3cm;">
              <v-img width="45" src="img/logo_iusa.svg" class="mt-n10" style="display: inline-block; margin-right: 0.2cm;"></v-img>
              <a href="" target="_blank" style="text-decoration: underline; color:black; display: inline-block;">
                {{ boxes[6].text }}
              </a>
            </div>
          </div>
        </div>
      </v-card>
    </v-container>
  </div>
</template>

<script>
  //import MenuPrint from '@/components/MenuPrint';
  import Document from '@/mixins/document';
  import Service from '@/mixins/service';
  import { mapActions } from "vuex";

  export default {
    name: 'Vinculos',

    mixins: [Document, Service],

    data() {
      return {
        boxes: [
          { title: 'Reporte de Backorder', text: '  IR A VÍNCULO' },
          { title: 'Reporte pedidos pendientes SAP', text: '  IR A VÍNCULO' },
          { title: 'Reporte de Backorder cancelados', text: '  IR A VÍNCULO' },
          { title: 'Reporte precios X Ofi. Cite. GMat4', text: '  IR A VÍNCULO' },
          { title: 'Pólitica de Entrega', text: '  DESCARGAR PDF' },
          { title: 'Pólitica de crédito', text: '  DESCARGAR PDF' },
          { title: 'Pólitica de autos', text: '  IR A VÍNCULO' },
        ]
      };
    },

    mounted() {
      this.sendToPDF({ active: true });
    },

    computed: { 
      isPr() {
        const channel = this.$store.getters['auth/channel'];
        return channel === 'PR'; 
      }
    },

    methods: {
      ...mapActions({
        sendToPDF: 'printer/sendToPDF'
      }),

      
    }
  };
</script>
<style scoped>
  .box {
    width: calc(50% - 60px); /* Modifica este valor según tus necesidades */
    max-width: 320px; /* Mantén un ancho máximo para que los elementos no se extiendan demasiado */
    height: 200px;
    margin: 30px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    text-align: center;
    float: left; /* Asegúrate de que los elementos se coloquen en línea */
  }

  .title {
    height: 40%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .content {
    height: 60%;
    padding: 10px;
  }

  /* Cambié las clases vinculo1, vinculo2, etc. a minúsculas para que coincidan con el HTML */
</style>
