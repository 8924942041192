<template>
  <div>
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        Pago en línea <span v-if="isAdvancePaymente">&nbsp;Anticipado </span>
      </v-card-title>
      <v-card-text>
        <v-alert
          icon="mdi-close-octagon"
          prominent
          type="error"
          v-if="showAlert"
        >
          <v-row align="center">
            <v-col class="grow">
              <span style="font-weight: 700; font-size: 16px">{{
                textAlert
              }}</span>
            </v-col>
          </v-row>
        </v-alert>
        <div v-if="isAdvancePaymente">
          <label for="" class="mb-2 ml-2 mt-5">Ingrese Monto:</label>
          <div class="input-group">
            <span class="input-group-text" id="basic-addon1"
              ><v-icon>mdi-credit-card</v-icon></span>
            <input
              class="form-control"
              placeholder="Monto"
              v-model="antpay"
              v-on:keypress="isNumber($event)"
              v-on:keyup="alertAmount"
              type="text"
            />
          </div>
        </div>
        <br />
        <p></p>
        <h6>Por favor, seleccione un banco:</h6>
        <bank-selector :value="bank" :userChannel="userChannel" @alertAmountChange="alertAmount($event)"/>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn color="error" text @click="closeForm"> Cancelar </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" text @click.prevent="validate()">
          Aceptar
        </v-btn>
      </v-card-actions>
    </v-card>
    <div>
      <form ref="form1" method="post" :action="urlbankSend">
        <input
          type="hidden"
          class="form-control"
          placeholder="mp_signature"
          name="mp_signature"
          v-model="form.mp_signature"
        />
        <input
          type="hidden"
          class="form-control"
          placeholder="mp_account"
          name="mp_account"
          v-model="form.mp_account"
        />
        <input
          type="hidden"
          class="form-control"
          placeholder="mp_product"
          name="mp_product"
          v-model="form.mp_product"
        />
        <input
          type="hidden"
          class="form-control"
          placeholder="mp_order"
          name="mp_order"
          v-model="form.mp_order"
        />
        <input
          type="hidden"
          class="form-control"
          placeholder="mp_reference"
          name="mp_reference"
          v-model="form.mp_reference"
        />
        <input
          type="hidden"
          class="form-control"
          placeholder="mp_node"
          name="mp_node"
          v-model="form.mp_node"
        />
        <input
          type="hidden"
          class="form-control"
          placeholder="mp_concept"
          name="mp_concept"
          v-model="form.mp_concept"
        />
        <input
          type="hidden"
          class="form-control"
          placeholder="mp_amount"
          name="mp_amount"
          v-model="form.mp_amount"
        />
        <input
          type="hidden"
          class="form-control"
          placeholder="mp_currency"
          name="mp_currency"
          v-model="form.mp_currency"
        />
        <input
          type="hidden"
          class="form-control"
          placeholder="mp_urlsuccess"
          name="mp_urlsuccess"
          v-model="form.mp_urlsuccess"
        />
        <input
          type="hidden"
          class="form-control"
          placeholder="mp_urlfailure"
          name="mp_urlfailure"
          v-model="form.mp_urlfailure"
        />
      </form>
    </div>
    <div>
      <form ref="form_b" method="post" :action="urlbankSendBamx">
        <input
          type="hidden"
          class="form-control"
          placeholder="EWF_SYS_0"
          name="EWF_SYS_0"
          v-model="form2.EWF_SYS_0"
        />
        <input
          type="hidden"
          class="form-control"
          placeholder="EWF_FORM_NAME"
          name="EWF_FORM_NAME"
          v-model="form2.EWF_FORM_NAME"
        />
        <input
          type="hidden"
          class="form-control"
          placeholder="BANKID"
          name="BANKID"
          v-model="form2.BANKID"
        />
        <input
          type="hidden"
          class="form-control"
          placeholder="PRODUCTNAME"
          name="PRODUCTNAME"
          v-model="form2.PRODUCTNAME"
        />
        <input
          type="hidden"
          class="form-control"
          placeholder="EWFBUTTON"
          name="EWFBUTTON"
          v-model="form2.EWFBUTTON"
        />
        <input
          type="hidden"
          class="form-control"
          placeholder="EXTRA1"
          name="EXTRA1"
          v-model="form2.EXTRA1"
        />
        <input
          type="hidden"
          class="form-control"
          placeholder="EXTRA2"
          name="EXTRA2"
          v-model="form2.EXTRA2"
        />
        <input
          type="hidden"
          class="form-control"
          placeholder="EXTRA3"
          name="EXTRA3"
          v-model="form2.EXTRA3"
        />
        <input
          type="hidden"
          class="form-control"
          placeholder="EXTRA4"
          name="EXTRA4"
          v-model="form2.EXTRA4"
        />
        <input
          type="hidden"
          class="form-control"
          placeholder="LANGUAJEID"
          name="LANGUAJEID"
          v-model="form2.LANGUAJEID"
        />
      </form>
    </div>
  </div>
</template>

<script>
import axios from "@/plugins/axios";
import numbersFormats from '@/mixins/numbersFormats';
import BankSelector from "@/components/forms/BankSelector.vue"; 

export default {
  name: "AccountStatusCard",
  props:{
    userChannel: {
      required: true,
      },
      customerCode:{
        required: true,
      },
      invoice:{
        required: false,
      },
      typePay:{
        required: true,
      }
  },
  components: {
    BankSelector,
  },
  mixins: [numbersFormats],
  data() {
    return {
      bank: 0,
      antpay: null,
      showAlert: false,
      textAlert: "mensaje",
      data: null,
      url: null,
      urlbank: process.env.VUE_APP_BBVA_URL,
      urlbankSend: process.env.VUE_APP_BBVA_SEND_URL,
      urlbankSendBamx: process.env.VUE_APP_BANAMEX_SEND_URL,
      form: {
        mp_signature: "",
        mp_account: "",
        mp_product: "",
        mp_order: "",
        mp_reference: "",
        mp_node: "",
        mp_concept: "",
        mp_amount: "",
        mp_currency: "",
        mp_urlsuccess: "",
        mp_urlfailure: "",
      },
      form2: {
        EWF_SYS_0: "",
        EWF_FORM_NAME: "",
        BANKID: "",
        PRODUCTNAME: "",
        EWFBUTTON: "",
        EXTRA1: "",
        EXTRA2: "",
        EXTRA3: "",
        EXTRA4: "",
        LANGUAJEID: "",
      },
    };
  },
  async mounted(){
   this.bank = 0;
  },
  methods: {
    alertAmount(event) {
      this.bank = event;
      this.showAlert = false;
    },
    validate() {
      if (this.bank != 0) {
        if (this.isAdvancePaymente) {
          if (this.antpay == null || this.antpay <= 0) {
            this.showAlert = true;
            this.textAlert = "Ingresar un monto por favor";
          } else {
            this.send();
          }
        } else {
          this.send();
        }
      } else {
        this.showAlert = true;
        this.textAlert = "Por favor, seleccione un banco...";
      }
    },
    send() {
      const selectedInvoice =
        this.typePay == 1 ? this.invoice : "1000000000," + this.antpay + ",0";

      const params = {
        invoice: selectedInvoice,
        bank: this.bank,
        custumer_code: this.customerCode,
        type_pay: this.typePay,
        channel: this.userChannel,
      };

      this.$swal({
        title: "¡ALERTA!",
        text: "A partir de este momento sales de dominio IEL y entras al sitio de tu banco.",
        icon: "warning",
        type: "warning",
        confirmButtonText: "CONTINUAR",
        confirmButtonColor: "#A82222",
        closeOnConfirm: true,
      }).then((confirmed) => {
        if (confirmed) {
         this.callingService(params);
        }
      });
    },
    async callingService(params) {
      const response = await axios.post("/sendPayment", params);

      if (response.data.MYERR == "1") {
        this.$swal({
          title: response.data.FNMSG,
          icon: "error",
          type: "error",
        });
      } else {
        const bankId = response.data.IDBANCO;
        this.serviceResponse(bankId, response);
      }
    },

    serviceResponse(bankId, response) {
      if (bankId == 2 || bankId == 12) {
        // bbva
        this.serviceResponseBBVA(response);
      } else if (bankId == 4 || bankId == 14) {
        // banamex
        this.serviceResponseBanamex(response);
      } else {
        //banorte (1,11), santander (3,13)
        window.location.href = response.data.LREFE;
      }
    },

    serviceResponseBBVA(response) {
      this.form = response.data;
      this.form.mp_signature = response.data.MPSIG;
      this.form.mp_account = response.data.MPACC;
      this.form.mp_product = response.data.MPPRO;
      this.form.mp_order = response.data.MPORD;
      this.form.mp_reference = response.data.MPREF;
      this.form.mp_node = response.data.MPNOD;
      this.form.mp_concept = response.data.MPCON;
      this.form.mp_amount = response.data.MPAMO;
      this.form.mp_currency = response.data.MPCUR;

      this.form.mp_urlsuccess = this.urlbank;
      this.form.mp_urlfailure = this.urlbank;

      this.$nextTick(function () {
        this.$refs.form1.submit();
      });
    },

    serviceResponseBanamex(response) {
      this.form2 = response.data;
      this.form2.EWF_SYS_0 = "4eebd5b1-3824-11d5-929d-0050dae9973a";
      this.form2.EWF_FORM_NAME = "account-status";
      this.form2.BANKID = "EDIFY";
      this.form2.PRODUCTNAME = "EBS";
      this.form2.EWFBUTTON = "";
      this.form2.EXTRA1 = "SPANISH";
      this.form2.EXTRA2 = response.data.EXTRA2;
      this.form2.EXTRA3 = "";
      this.form2.EXTRA4 = "NO_ERROR";
      this.form2.LANGUAJEID = "1";

      this.$nextTick(function () {
        this.$refs.form_b.submit();
      });
    },
    closeForm() {
      this.bank = 0;
      this.antpay = null;
      this.showAlert = false;
      this.$emit("close", true);
    },
  },
  computed: {
      isAdvancePaymente(){
      if(this.typePay == 2){
        return true;
      }
      return false;
    }
  },
};
</script>
