<template>
  <v-btn 
    icon 
    @click="launchDataStep" 
    :class="{ 'mr-5': isLarge }"
    :height="isLarge ? 45 : undefined" 
    :width="isLarge ? 45 : undefined"
  >
    <v-icon :size="isLarge ? 45 : undefined" style="color: black;">mdi-account-edit</v-icon>
  </v-btn>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'DataUpdateStepLauncher',

  props: {
    isLarge: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    ...mapActions('modalStepsAfterLogin', [
      'updateModalState',
      'findStepByKey',
      'changeCurrentStepValue',
      'updateStepStatus',
      'syncSteps',
      'loadSteps'
    ]),
    ...mapActions("updateData", ["setMustUpdateData"]), 
    
    async launchDataStep () {
      await this.loadSteps();
      this.syncSteps();

      const updateDataStep = await this.findStepByKey('updateData');

      if(updateDataStep) {
        updateDataStep.visited = true;
        this.setMustUpdateData(true); 
        this.updateStepStatus(updateDataStep);
        this.changeCurrentStepValue(updateDataStep.value);
        this.updateModalState(true);
      }
    }
  }
};
</script>